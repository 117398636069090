import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import Container from '@beelineloans/cx-library/src/components/layout/Container';
import { H2, H3, Span, Paragraph } from '@beelineloans/cx-library/src/components/typography';
// import { SearchField } from '@beelineloans/cx-library/src/components/forms/fields';
import CONST from '@beelineloans/cx-library/src/utils/constants';
// import { Theme } from '@beelineloans/cx-library/src/theme/default';
// import { buildKBURL } from '@beelineloans/cx-library/src/utils/helpers';
// import Lamp from '@beelineloans/cx-library/src/images/lamp-solo.png';
// import ArrowIcon from '@beelineloans/cx-library/src/images/icons/arrow-right.png';
import {
  ButtonText,
  // ButtonOutline
} from '../../../shared/GatsbyButton';

const ContainerStyled = styled(Container)`
	.container-inner {
		display: grid;
		grid-template-columns: 100%;
		grid-template-areas: ".";
		grid-column-gap: 0px;
		grid-row-gap: 0;
		align-items: center;
		width: 100%;

		@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
			grid-template-columns: 50% 50%;
			grid-template-areas: ". .";
		}
	}
`;

const Half = styled.div`
  border-top:       1px solid ${props => props.theme.colours.border.default};
  padding-top:      50px;
  padding-bottom:   80px;
  align-self:       flex-start;

  ${props => props.noborder && css`
			border-top: 0px;
		`}

  @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
    margin:           0;
    padding-bottom:   100px;
    &:nth-child(odd) {
      padding-right:  50px;
    }
  }
`;

const Heading = styled(H3)`
	padding-bottom: 20px;
`;

const FAQLink = styled(ButtonText)`
	display: block;
	padding-bottom: 15px;
`;

// const ContainerLamp = styled(Container)`
// 	.container-inner {
// 		padding-bottom: 400px;
// 	}

// 	background-image: url(${Lamp});
// 	background-repeat: no-repeat;
// 	background-size: 245px 320px;
// 	background-position: bottom center;

// 	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
// 		background-size: 347px 434px;
// 		background-position: bottom right 10%;

// 		.container-inner {
// 			padding-top: 200px;
// 			padding-bottom: 200px;
// 		}
// 	}

// 	@media only screen and (${props => props.theme.mediaBreakpoints.desktop}) {
// 	}
// `;

// const ArrowImg = styled.img`
// 	padding-left: 10px;
// `;

const ParagraphStyled = styled(Paragraph)`
	margin: 15px 0 0;
`;

const FAQBlock = ({ faqs }) => {
  if (!faqs || faqs.length === 0) return null;

  const featured = faqs.filter(c => c.featured === true);

  if (featured && featured.length > 0) {
    const result = (
      <Half>
        <Heading>{featured[0].category.name}</Heading>
        {featured
          .map((a) => (
            <FAQLink key={a.article_id} to={a.url}>
              {a.title}
            </FAQLink>
          ))}
      </Half>
    );

    return result;
  }

  return null;
};

FAQBlock.propTypes = {
  faqs: PropTypes.array.isRequired
};

const FAQ = ({ pageContext }) => {
  // const [search, setSearch] = useState('');
  const { faqContent } = pageContext;
  const categories = {};

  faqContent.forEach(faq => {
    if (faq.category && faq.category.name) {
      if (!categories[faq.category.name]) {
        categories[faq.category.name] = [];
      }

      categories[faq.category.name].push(faq);
    }
  });

  // const updateSearch = (event) => {
  //   setSearch(event.target.value);
  // };

  // const runSearch = () => {
  //   document.location.href = buildKBURL(
  //     search.trim() === '' ? '' : `/en_us/search?q=${search}`
  //   );
  //   return false;
  // };

  return (
    <NavOnlyTemplate
      headerProps={{
        hideButton: true
      }}
    >
      <SEO
        path={CONST.LINKS.MAIN.FAQ}
        title="Frequently asked questions"
        description="Got questions? We've got answers. Search our knowledge base to explore all things Beeline."
      />
      <ContainerStyled>
        <Half noborder>
          <H2>Need answers?</H2>
          <Span>Browse the topics below.</Span>
        </Half>
        <Half noborder>
          <H2>Prefer to chat?</H2>
          <ParagraphStyled>{CONST.LINKS.EXTERNAL.PHONE.DASHED}</ParagraphStyled>
          <ParagraphStyled>
            {CONST.LINKS.EXTERNAL.EMAIL.DEFAULT}
          </ParagraphStyled>
          <ParagraphStyled>{CONST.BUSINESS_HOURS.LINE1}<br />{CONST.BUSINESS_HOURS.LINE2}</ParagraphStyled>
        </Half>
        {categories && Object.keys(categories).map(cat => (
          <FAQBlock faqs={categories[cat]} />
        ))}
      </ContainerStyled>
      {/* <ContainerLamp backgroundColor={Theme.colours.background.yellow} center>
        <H2 textAlign="center">Got a specific question? Lay it on us.</H2>
        <SearchField
          placeholder="Start by searching here"
          id="search"
          value={search}
          onInputChange={updateSearch}
          onEnter={runSearch}
        />
        <ButtonOutline onClick={runSearch}>
          See all
          <ArrowImg src={ArrowIcon} width="10" height="10" />
        </ButtonOutline>
      </ContainerLamp> */}
    </NavOnlyTemplate>
  );
};

FAQ.propTypes = {
  pageContext: PropTypes.object
};

FAQ.defaultProps = {};

export default FAQ;
